import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import '../css/custom.css'
import { BottomNavigation, BottomNavigationAction, TextField } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Form, Formik,useFormik } from 'formik';
import * as yup from 'yup';
import { AddnewOrder, checkfleetavailble, getLocationList, orderListFleetId } from '../ApI/Index';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from 'react-redux';
import { Opendoor } from '../redux/actions';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor:'rgb(40,47,66)',
  boxShadow: 24,
  p: 4,
  borderRadius:'10px',
  paddingTop: '11px',
  border:'none',
  paddingRight:'0px',
  paddingLeft:'0px'
};

 function AddNewOrder(props) {

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const [locationlist, setlocationlist] =React.useState()
  const dispatch = useDispatch()
  const [neworderlist, setneworderlist] =React.useState()
  const [checkaval, setcheckaval] = React.useState() 
  const handleClose = (reason) => {
    if (reason && reason == "backdropClick") 
        return;
     setOpen(false);
  
  }
  var fleetId = localStorage.getItem('fleetId')

  React.useEffect(()=>{
    setInterval(()=>{
      getLocationList(fleetId, token).then((res)=>{
      //  console.log('Location List response', res) 
       setlocationlist(res.data.data)
      })
      .catch((err)=>{
       console.log('Location List response', err) 
       })
      },12000)
    //   setInterval(()=>{
    //   checkfleetavailble(fleetId,token).then((res)=>{
    //     console.log('Check Fleet Available Response', res)
    //     if(res.data.message==="fleet unavailable"){
    //       setcheckaval(false) 
    //      }
    //     else {
    //       setcheckaval(true) 
    //     } 
    //   }).catch((err)=>{
    //      console.log("Error in check fleet available", err)
    //   })
    //  },9000)
  },[])
    const notifysuccess = () => toast.success("New Order Added");
    const notifyfialed=()=> toast.error('New Order not added')
    const validationSchema = yup.object({
    deliverylocation:yup.string()
                .required('Please Select Delivery Location'),
   pickuplocation:yup.string().required('Please Select PickUp Location'),
   orderID:yup.string().required('Please Enter OrderID')
})  
const orderlist =useSelector(state=> state.OrderAction)
let useremail = localStorage.getItem('useremail')
const token= localStorage.getItem('token')
const formik = useFormik({
    initialValues: {
       orderID:'',
       deliverylocation:'',
       pickuplocation:'',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {

    AddnewOrder(values,fleetId,useremail,token).then((res)=>{
       console.log('Add New Order', res)
       handleClose()
       notifysuccess()
    })
    .catch((err)=>{
      notifyfialed()
      console.log('Add new Order Error', err)
    })
    orderListFleetId(fleetId ,1, 10).then((res)=>{
        setneworderlist(res.data)
         console.log('NewOrderList',res) 
        handleClose() 
        dispatch(Opendoor(neworderlist?.data?.data?.order))
     })
     .catch(err=>{
      console.log(err)
     })
 
     console.log('values',values)
    }
  });




  return (
    <>
      {/* {checkaval ? */}
      <Button className="Header-Add-new-order-btn" onClick={handleOpen} style={{marginRight:'15px'}}>  Add New Order</Button>
{/*         
      :  <Button disabled={true} style={{opacity:'0.2' , backgroundColor:'black', color:'white' , borderRadius:'20px',padding: "6px 20px"}} className="Dashboard-table-action-btn"> Add New Order</Button>  
    } */}

      {/* {console.log('OrderListList', props.data)} */}
      <Modal
        open={open}
        // BackdropProps={false}
        onHide={handleClose}
        // onClose={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"  
      >
        <Box sx={style} >
            <form onSubmit={formik.handleSubmit}>
            <div className='AddNew-order-Modal-Mainwrapper'>
               <div className='AddNew-order-Modal-div-one d-flexx-center'> 
                 <h3> Add New Order</h3>
                   <Button className="" onClick={handleClose}><CancelOutlinedIcon style={{color:'rgb(189,196,224)',fontWeight: "600", fontSize: '35px'}}/> </Button>
               </div>
                 <div className='AddNew-order-Modal-div-two d-flexx-center'> 
                  <div className='AddNew-order-Modal-div-two-inner-one'> 
                       <h4>Place Order(Order ID)<sup className="sup-red">*</sup></h4>
                       <TextField
                            id="standard-size-normal"
                            fullWidth
                            variant="standard"
                            value={formik.values.orderID}
                            name="orderID"
                            onChange={formik.handleChange}
                            error={
                                formik.touched.orderID &&
                                Boolean(formik.errors.orderID)
                            }
                            helperText={
                                formik.touched.orderID && formik.errors.orderID
                            }
                            
                            />
                    
                  </div>
                  <div className='AddNew-order-Modal-div-two-inner-two'> 
                       <h4>Pick Up Location<sup className="sup-red">*</sup></h4>
                       <div className="BottomNavigation-Wrapper">
                      <BottomNavigation
                            className="BottomNavigation_Wrapper_inner_wrapper"
                            showLabels
                            name="pickuplocation"
                            value={formik.values.pickuplocation}
                            onChange={(event, newValue) => {
                                formik.setFieldValue('pickuplocation',newValue)
                            
                            }}
                            error={formik.touched.pickuplocation && Boolean(formik.errors.pickuplocation)}
                            helperText={formik.touched.pickuplocation && formik.errors.pickuplocation}  
                            >
                            {locationlist?.map((item)=>{
                               
                               if(item.locationType==="PICKUP"){
                               return(
                                
                                  <BottomNavigationAction label={item.locationName} value={item.locationName} className='BottomNavigate-btn'/>
                                  
                                )
                               }
                              }
                            )}  
                            
                      </BottomNavigation>
                      </div>
                      {(formik.touched.pickuplocation &&formik.errors.pickuplocation)? 
                        <p style={{color:'#d32f2f', textAlign:'left', marginTop:'10px',fontSize:'11px', paddingLeft: '27px'}}> Please Select PickUp Location</p>:null }
                      
                  </div>
                 </div>
                  <div className='AddNew-order-Modal-div-three'>
                  <h4>Delivery Location<sup className="sup-red">*</sup></h4>
                  <div className="BottomNavigation-Wrapper">
                     <BottomNavigation
                            className="BottomNavigation_Wrapper_inner_wrapper"
                            showLabels
                            name="deliverylocation"
                            value={formik.values.deliverylocation}
                            onChange={(event, newValue) => {
                                formik.setFieldValue('deliverylocation',newValue)
                            
                            }}
                            error={formik.touched.deliverylocation && Boolean(formik.errors.deliverylocation)}
                            helperText={formik.touched.deliverylocation && formik.errors.deliverylocation}  
                            >
                            {locationlist?.map((item)=>{
                                if(item.locationType==="DROPOFF"){
                                  return(
                                    <BottomNavigationAction label={item?.locationName} value={item?.locationName} className='BottomNavigate-btn'/> 
                                  )}}
                              )}    
                        </BottomNavigation>
                       </div>   
                      {(formik.touched.deliverylocation &&formik.errors.deliverylocation)? 
               <p style={{color:'#d32f2f', textAlign:'left', marginTop:'10px',fontSize:'11px', paddingLeft: '27px'}}> Please Select Delivery Location</p>:null }
                  </div>
                  <div className='AddNewOrder-page-Submit0btn-wrapper'> 
                      <Button type="submit">Submit </Button>
               </div>
               </div>
              
               </form>
        </Box>
      </Modal>
      <ToastContainer />
    </>
  );
}
export default React.memo(AddNewOrder)